.contactRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cLink:link,
.cLink:active {
  background-color: rgba(0, 0, 0, 0.5);
  padding: .2em;
  color: #fff;
}

.cLink:hover {
  color: #0000ff;
}
