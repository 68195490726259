.titleRow {
  text-align: center;
}

.titleRow span {
  display: block;
}

.title {
  font-size: 2em;
  margin-bottom: 0.3em;
}

.subTitle {
  padding: 0.3em;
  color: #000;
}
