.socialRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialIcon {
  font-size: 2em;
  transition: color 0.3s ease;
  padding: 0 0.3em;
}

.socialIcon a {
  color: #777;
}

.sit a:hover {
  color: #1da1f2;
}

.sil a:hover {
  color: #2867b2;
}

.sig a:hover {
  color: #333;
}
