body {
  padding: 0;
  margin: 0;
  font-family: "Source Code Pro", monospace;
}

.decorator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main {
  height: 100vh;
}
